import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      codeDisclaimer: this.props.campaign_package.code_disclaimer,
      discountAmount: this.props.campaign_package.discount_amount,
      engagementLink: this.props.campaign_package.engagement_link,
      errors: '',
      instructionTextAppend: this.props.campaign_package.instruction_text_append,
      marketingNotificationAppend: this.props.campaign_package.marketing_notification_append,
      marketingTextAppend: this.props.campaign_package.marketing_text_append,
      marketingTextInterval: this.props.campaign_package.marketing_text_interval,
      name: this.props.campaign_package.name,
      onDesktop: true,
      productReviewTextAppend: this.props.campaign_package.product_review_text_append,
      productReviewTextInterval: this.props.campaign_package.product_review_text_interval,
      rebateDisclaimer: this.props.campaign_package.rebate_disclaimer,
      rebateDiscountAmount: this.props.campaign_package.rebate_discount_amount,
      rebateResponse: this.props.campaign_package.rebate_response,
      reminderReviewText: this.props.campaign_package.reminder_review_text,
      reminderReviewTextInterval: this.props.campaign_package.reminder_review_text_interval,
      sendReminderReviewText: this.props.campaign_package.send_reminder_review_text,
      selectedDiscountTarget: this.props.campaign_package.discount_target,
      selectedDiscountType: this.props.campaign_package.discount_type,
      selectedEnableRebate: this.props.campaign_package.enable_rebate,
      selectedProductReviewProductId: this.props.campaign_package.product_review_product_id,
      selectedProductReviewText: this.props.campaign_package.product_review_product_id ? true : false,
      selectedRebateDiscountType: this.props.campaign_package.rebate_discount_type,
      selectedWave: this.props.campaign_package.wave.id,
      staticCode: this.props.campaign_package.static_code,
      transactionTextAppend: this.props.campaign_package.transaction_text_append,
    };

    this.changeCodeDisclaimer = this.changeCodeDisclaimer.bind(this)
    this.changeDiscountAmount = this.changeDiscountAmount.bind(this)
    this.changeDiscountTarget = this.changeDiscountTarget.bind(this)
    this.changeDiscountType = this.changeDiscountType.bind(this)
    this.changeEnableRebate = this.changeEnableRebate.bind(this)
    this.changeEngagementLink = this.changeEngagementLink.bind(this)
    this.changeInstructionTextAppend = this.changeInstructionTextAppend.bind(this)
    this.changeMarketingNotificationAppend = this.changeMarketingNotificationAppend.bind(this)
    this.changeMarketingTextAppend = this.changeMarketingTextAppend.bind(this)
    this.changeMarketingTextInterval = this.changeMarketingTextInterval.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeProductReviewProductId = this.changeProductReviewProductId.bind(this)
    this.changeProductReviewText = this.changeProductReviewText.bind(this)
    this.changeProductReviewTextAppend = this.changeProductReviewTextAppend.bind(this)
    this.changeProductReviewTextInterval = this.changeProductReviewTextInterval.bind(this)
    this.changeRebateDisclaimer = this.changeRebateDisclaimer.bind(this)
    this.changeRebateDiscountAmount = this.changeRebateDiscountAmount.bind(this)
    this.changeRebateDiscountType = this.changeRebateDiscountType.bind(this)
    this.changeRebateResponse = this.changeRebateResponse.bind(this)
    this.changeReminderReviewText = this.changeReminderReviewText.bind(this)
    this.changeReminderReviewTextInterval = this.changeReminderReviewTextInterval.bind(this)
    this.changeSendReminderReviewText = this.changeSendReminderReviewText.bind(this)
    this.changeStaticCode = this.changeStaticCode.bind(this)
    this.changeTransactionTextAppend = this.changeTransactionTextAppend.bind(this)
    this.changeWave = this.changeWave.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeCodeDisclaimer(event) {
    this.setState({
      codeDisclaimer: event.target.value
    })
  }

  changeDiscountAmount(event) {
    this.setState({
      discountAmount: event.target.value
    })
  }

  changeDiscountTarget(event) {
    this.setState({
      selectedDiscountTarget: event.target.value,
    })
  }

  changeDiscountType(event) {
    this.setState({
      selectedDiscountType: event.target.value,
    })
  }

  changeEnableRebate() {
    let enableRebate = !this.state.selectedEnableRebate;

    this.setState({
      selectedEnableRebate: enableRebate,
      rebateDiscountAmount: enableRebate ? this.state.rebateDiscountAmount : this.props.campaign_package.rebate_discount_amount,
      selectedRebateDiscountType: enableRebate ? this.state.selectedRebateDiscountType : this.props.campaign_package.rebate_discount_type,
    })
  }

  changeEngagementLink(event) {
    this.setState({
      engagementLink: event.target.value
    })
  }

  changeInstructionTextAppend(event) {
    this.setState({
      instructionTextAppend: event.target.value
    })
  }

  changeMarketingNotificationAppend(event) {
    this.setState({
      marketingNotificationAppend: event.target.value
    })
  }

  changeMarketingTextAppend(event) {
    this.setState({
      marketingTextAppend: event.target.value
    })
  }

  changeMarketingTextInterval(event) {
    this.setState({
      marketingTextInterval: event.target.value
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  changeProductReviewProductId(event) {
    this.setState({
      selectedProductReviewProductId: event.target.value
    });
  }

  changeProductReviewText() {
    let enableProductReviewText = !this.state.selectedProductReviewText;

    this.setState({
      selectedProductReviewText: enableProductReviewText,
      selectedProductReviewProductId: enableProductReviewText ? this.state.selectedProductReviewProductId : this.props.campaign_package.product_review_product_id,
      productReviewTextAppend: enableProductReviewText ? this.state.productReviewTextAppend : this.props.campaign_package.product_review_text_append,
      productReviewTextInterval: enableProductReviewText ? this.state.productReviewTextInterval : this.props.campaign_package.product_review_text_interval,
    })
  }

  changeProductReviewTextAppend(event) {
    this.setState({
      productReviewTextAppend: event.target.value
    })
  }

  changeProductReviewTextInterval(event) {
    this.setState({
      productReviewTextInterval: event.target.value
    })
  }

  changeRebateDisclaimer(event) {
    this.setState({
      rebateDisclaimer: event.target.value
    })
  }

  changeRebateDiscountAmount(event) {
    this.setState({
      rebateDiscountAmount: event.target.value
    })
  }

  changeRebateDiscountType(event) {
    this.setState({
      selectedRebateDiscountType: event.target.value,
    })
  }

  changeRebateResponse(event) {
    this.setState({
      rebateResponse: event.target.value
    })
  }

  changeReminderReviewText(event) {
    this.setState({
      reminderReviewText: event.target.value,
    })
  }

  changeReminderReviewTextInterval(event) {
    this.setState({
      reminderReviewTextInterval: event.target.value,
    })
  }

  changeSendReminderReviewText() {
    let sendReminderReviewText = !this.state.sendReminderReviewText;

    this.setState({
      sendReminderReviewText: sendReminderReviewText,
    })
  }

  changeStaticCode(event) {
    this.setState({
      staticCode: event.target.value
    })
  }

  changeTransactionTextAppend(event) {
    this.setState({
      transactionTextAppend: event.target.value
    })
  }

  changeWave(event) {
    let waveId = Number(event.target.value);

    this.setState({
      selectedWave: waveId,
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      code_disclaimer: this.state.codeDisclaimer,
      discount_amount: this.state.discountAmount,
      discount_target: this.state.selectedDiscountTarget,
      discount_type: this.state.selectedDiscountType,
      enable_rebate: this.state.selectedEnableRebate,
      engagement_link: this.state.engagementLink,
      instruction_text_append: this.state.instructionTextAppend,
      marketing_notification_append: this.state.marketingNotificationAppend,
      marketing_text_append: this.state.marketingTextAppend,
      marketing_text_interval: this.state.marketingTextInterval,
      name: this.state.name,
      product_review_product_id: this.state.selectedProductReviewProductId,
      product_review_text_append: this.state.productReviewTextAppend,
      product_review_text_interval: this.state.productReviewTextInterval,
      rebate_disclaimer: this.state.rebateDisclaimer,
      rebate_discount_amount: this.state.rebateDiscountAmount,
      rebate_discount_type: this.state.selectedRebateDiscountType,
      rebate_response: this.state.rebateResponse,
      reminder_review_text: this.state.reminderReviewText,
      reminder_review_text_interval: this.state.reminderReviewTextInterval,
      send_reminder_review_text: this.state.sendReminderReviewText,
      static_code: this.state.staticCode,
      transaction_text_append: this.state.transactionTextAppend,
      wave_id: this.state.selectedWave,
    })
    formData.append('campaign_package', data)

    fetch(this.props.campaign_package.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let {
      campaign_package,
      discount_targets,
      discount_types,
      products,
      showdrop_logo,
      url_new,
      waves,
    } = this.props;
    let {
      codeDisclaimer,
      discountAmount,
      engagementLink,
      errors,
      instructionTextAppend,
      marketingNotificationAppend,
      marketingTextAppend,
      marketingTextInterval,
      name,
      onDesktop,
      productReviewTextAppend,
      productReviewTextInterval,
      rebateDisclaimer,
      rebateDiscountAmount,
      rebateResponse,
      reminderReviewText,
      reminderReviewTextInterval,
      sendReminderReviewText,
      selectedDiscountTarget,
      selectedDiscountType,
      selectedEnableRebate,
      selectedProductReviewProductId,
      selectedProductReviewText,
      selectedRebateDiscountType,
      selectedWave,
      staticCode,
      transactionTextAppend,
    } = this.state;
    let pageName = 'brands';

    const showProductReviewReminderTextFields = () => {
      if (sendReminderReviewText) {
        return (
          <>
            <dt className="col-sm-3 text-right">
              Product Review Reminder Text
              <div className="fs-10 thin">(Text sent to a customer if they never reviewed the product)</div>
              <div className="fs-10 thin">{`(Add "{{product_review_link}}" to insert a customer specific product review page URL)`}</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <textarea className="form-control" placeholder="Product Review Reminder Text" onChange={this.changeReminderReviewText} value={reminderReviewText} />
              </div>
            </dd>

            <dt className="col-sm-3 text-right">
              Product Review Text Reminder Interval
              <div className="fs-10 thin">(How soon after will the customer be sent their reminder text.)</div>
              <div className="fs-10 thin">(NOTE: This value will be combined with the "Product Review Text Interval" value)</div>
              <div className="fs-10 thin">(In Hours (decimals are ok))</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Product Review Text Reminder Interval" onChange={this.changeReminderReviewTextInterval} value={reminderReviewTextInterval} />
              </div>
            </dd>
          </>
        )
      } else {
        return null;
      }
    }

    const showProductReviewTextFields = () => {
      if (selectedProductReviewText) {
        return (
          <>
            <dt className="col-sm-3 text-right">
              Product to Review
              <div className="fs-10 thin">(The product that a customer will be rating in a text)</div>
            </dt>
            <dd className="col-sm-9">
              <select className="form-select" value={selectedProductReviewProductId} onChange={this.changeProductReviewProductId}>
                <option value="" selected>Select a Product</option>

                {products.map(product => {
                  return (
                    <option key={product.id} value={product.id}>{product.name}</option>
                  )
                })}
              </select>
            </dd>

            <dt className="col-sm-3 text-right">
              Product Review Text Append
              <div className="fs-10 thin">(Text sent to a customer to give a rating for a product)</div>
              <div className="fs-10 thin">{`(Add "{{product_review_link}}" to insert a customer specific product review page URL)`}</div>
              <div className="fs-10 thin">{`(Add "{{aisle_location}}" to insert dynamic campaign aisle location)`}</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <textarea className="form-control" placeholder="Product Review Text Append" onChange={this.changeProductReviewTextAppend} value={productReviewTextAppend} disabled={selectedProductReviewProductId ? "" : "disabled"} />
              </div>
            </dd>

            <dt className="col-sm-3 text-right">
              Product Review Text Interval
              <div className="fs-10 thin">(How soon after will the customer be sent their product review text.)</div>
              <div className="fs-10 thin">(In Hours (decimals are ok))</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Product Review Text Interval" onChange={this.changeProductReviewTextInterval} value={productReviewTextInterval} disabled={selectedProductReviewProductId ? "" : "disabled"} />
              </div>
            </dd>

            <dt className="col-sm-3 text-right">
              Send Review Reminder Text?
              <div className="fs-10 thin">(Do we want to send a customer a reminder to review their sample?)</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" onChange={this.changeSendReminderReviewText} checked={sendReminderReviewText} />
                </div>
              </div>
            </dd>

            {showProductReviewReminderTextFields()}
          </>
        )
      } else {
        return null;
      }
    }
    const showRebateFields = () => {
      if (selectedEnableRebate) {
        return (
          <>
            <dt className="col-sm-3 text-right">
              Rebate Discount Amount
              <div className="fs-10 thin">(The discount amount only: Ex. 20)</div>
              <div className="fs-10 thin">(NOTE (if discount_type is "percentage"): setting this to 100 means "First Item Free")</div>
              <div className="fs-10 thin">(NOTE (if discount_type is "percentage"): setting this to 101 means "Buy One Get One Free")</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Rebate Discount Amount" onChange={this.changeRebateDiscountAmount} value={rebateDiscountAmount} />
              </div>
            </dd>

            <dt className="col-sm-3 text-right">
              Rebate Discount Type
              <div className="fs-10 thin">(The type of discount: Ex. percentage)</div>
            </dt>
            <dd className="col-sm-9">
              <select className="form-select" value={selectedRebateDiscountType} onChange={this.changeRebateDiscountType}>
                <option value="" disabled selected>Select a Rebate Discount Type</option>

                {discount_types.map(discount_type => {
                  return (
                    <option key={discount_type} value={discount_type}>{discount_type}</option>
                  )
                })}
              </select>
            </dd>

            <dt className="col-sm-3 text-right">
              Rebate Welcome Text
              <div className="fs-10 thin">{"(The 1st text we send out to a customer after they submit their phone number.)"}</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <textarea type="text" className="form-control" placeholder="Rebate Welcome Text" onChange={this.changeRebateResponse} value={rebateResponse} />
              </div>
            </dd>

            <dt className="col-sm-3 text-right">
              Rebate Disclaimer
              <div className="fs-10 thin">(The text found on the mobile app at the bottom of a rebate)</div>
            </dt>
            <dd className="col-sm-9">
              <div className="input-group mb-3">
                <textarea type="text" className="form-control" placeholder="Rebate Disclaimer" onChange={this.changeRebateDisclaimer} value={rebateDisclaimer} />
              </div>
            </dd>
          </>
        )
      } else {
        return null;
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={`${campaign_package.name}`}
              url_show={campaign_package.url}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right" style={{ marginBottom:"10px" }}>
                <span style={{ color:"red",fontSize:"10px" }}>*Required</span>
              </dt>
              <dd className="col-sm-9"></dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Name
                <div className="fs-10 thin">(Name of Package)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name of Package" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Wave
                <div className="fs-10 thin">(What wave does this package belong to?)</div>
              </dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedWave} onChange={this.changeWave}>
                  <option value="" selected>Select a Wave</option>

                  {waves.map(wave => {
                    return (
                      <option key={wave.id} value={wave.id}>{wave.name}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Static Discount Name
                <div className="fs-10 thin">(Campaign with 1 code for everyone)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Static Code" onChange={this.changeStaticCode} value={staticCode} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Discount Amount
                <div className="fs-10 thin">(The discount amount only: Ex. 20)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Discount Amount" onChange={this.changeDiscountAmount} value={discountAmount} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Discount Type
                <div className="fs-10 thin">(The type of discount: Ex. percentage)</div>
              </dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedDiscountType} onChange={this.changeDiscountType}>
                  <option value="" disabled selected>Select a Discount Type</option>

                  {discount_types.map(discount_type => {
                    return (
                      <option key={discount_type} value={discount_type}>{discount_type}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">
                Discount Target
                <div className="fs-10 thin">(Where is the discount applied?: Ex. line_item)</div>
              </dt>
              <dd className="col-sm-9">
                <select className="form-select" value={selectedDiscountTarget} onChange={this.changeDiscountTarget}>
                  <option value="" disabled selected>Select a Discount Target</option>

                  {discount_targets.map(discount_target => {
                    return (
                      <option key={discount_target} value={discount_target}>{discount_target}</option>
                    )
                  })}
                </select>
              </dd>

              <dt className="col-sm-3 text-right">
                Code Disclaimer
                <div className="fs-10 thin">(The text found on the mobile app at the bottom of a discount)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea type="text" className="form-control" placeholder="Code Disclaimer" onChange={this.changeCodeDisclaimer} value={codeDisclaimer} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Transaction Text Append
                <div className="fs-10 thin">(Text sent when a customer gets a PIN)</div>
                <div className="fs-10 thin">{`(Add "{{shopping_link}}" to insert dynamic shopping URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{engagement_link}}" to insert dynamic engagement URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{product_review_link}}" to insert a customer specific product review page URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{aisle_location}}" to insert dynamic campaign aisle location)`}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea className="form-control" placeholder="Transaction Text Append" onChange={this.changeTransactionTextAppend} value={transactionTextAppend} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Instruction Text Append
                <div className="fs-10 thin">(Text sent to a customer after they recieve a product that gives them direction or info about the product)</div>
                <div className="fs-10 thin">{`(Add "{{shopping_link}}" to insert dynamic shopping URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{engagement_link}}" to insert dynamic engagement URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{product_review_link}}" to insert a customer specific product review page URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{aisle_location}}" to insert dynamic campaign aisle location)`}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea className="form-control" placeholder="Instruction Text Append" onChange={this.changeInstructionTextAppend} value={instructionTextAppend} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Marketing Text Append
                <div className="fs-10 thin">(Text sent much later to a customer after they have tried a product)</div>
                <div className="fs-10 thin">{`(Add "{{shopping_link}}" to insert dynamic shopping URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{engagement_link}}" to insert dynamic engagement URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{product_review_link}}" to insert a customer specific product review page URL)`}</div>
                <div className="fs-10 thin">{`(Add "{{aisle_location}}" to insert dynamic campaign aisle location)`}</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea className="form-control" placeholder="Marketing Text Append" onChange={this.changeMarketingTextAppend} value={marketingTextAppend} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Marketing Notification Append
                <div className="fs-10 thin">(Notification sent to a MOBILE customer after they recieve a product)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <textarea className="form-control" placeholder="Marketing Notification Append" onChange={this.changeMarketingNotificationAppend} value={marketingNotificationAppend} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Marketing Text Interval
                <div className="fs-10 thin">(How soon after will the customer be sent their marketing text.)</div>
                <div className="fs-10 thin">(In Hours (decimals are ok))</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Marketing Text Interval" onChange={this.changeMarketingTextInterval} value={marketingTextInterval} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Engagement Link
                <div className="fs-10 thin">(An external link that you want to track clicks on.)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Engagement Link" onChange={this.changeEngagementLink} value={engagementLink} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                Enable Product Review via Text?
                <div className="fs-10 thin">(Setup a text based product review)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeProductReviewText} checked={selectedProductReviewText} />
                  </div>
                </div>
              </dd>

              {showProductReviewTextFields()}

              <dt className="col-sm-3 text-right">
                Enable Rebate?
                <div className="fs-10 thin">(Allows customers to go to the rebate page)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" onChange={this.changeEnableRebate} checked={selectedEnableRebate} />
                  </div>
                </div>
              </dd>

              {showRebateFields()}

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Campaign Package</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
