import React from "react"
import Nav from "../../shared/Nav";
import IndexHeader from "../../shared/headers/IndexHeader";
import AdminHeader from "../../shared/headers/AdminHeader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Column, Table, AutoSizer, defaultTableRowRenderer } from 'react-virtualized';
import Select from "react-select";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      downloading: false,
      errors: '',
      eventNames: [],
      fullyLoaded: false,
      loadingEvents: false,
      onDesktop: true,
      selectedEventType: "",
      selectedMachineId: "",
      selectedCustomerId: "",
      showHidden: true,
      sortedEvents: [],
      page: 1,
    }

    this.downloadCsv = this.downloadCsv.bind(this)
    this.downloadMachineLogCsv = this.downloadMachineLogCsv.bind(this)
    this.downloadTransactionDetailsCsv = this.downloadTransactionDetailsCsv.bind(this)
    this.filterEventsByMachine = this.filterEventsByMachine.bind(this)
    this.filterEventsByType = this.filterEventsByType.bind(this)
    this.setSelectedCustomerId = this.setSelectedCustomerId.bind(this)
    this.getEvents = this.getEvents.bind(this)
    this.toggleHidden = this.toggleHidden.bind(this)
    this.setPage = this.setPage.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })

    this.getEvents(1, null, null, null, true)
  }

  downloadCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `events_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  downloadMachineLogCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_machine_logs_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `machine-logs_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  downloadTransactionDetailsCsv() {
    let that = this;
    that.setState({
      downloading: true
    })

    fetch(this.props.url_download_transaction_details_csv, {
      method: 'GET',
      headers: {}
    }).then(response => {
      return response.blob();
    }).then((blob) => {
      that.setState({
        downloading: false
      })
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      var today = new Date(),
      date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      link.setAttribute(
        'download',
        `transaction-details_${date}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
  }

  filterEventsByMachine(event) {
    let machineId = event.target.value ? Number(event.target.value) : null

    this.setState({
      selectedMachineId: machineId,
    })

    this.getEvents(this.state.page, this.state.selectedEventType, machineId, this.state.selectedCustomerId, this.state.showHidden)
  }

  filterEventsByType(event) {
    let eventType = event.target.value;

    this.setState({
      selectedEventType: eventType,
    })

    this.getEvents(this.state.page, eventType, this.state.selectedMachineId, this.state.selectedCustomerId, this.state.showHidden)
  }

  setPage(page) {
    this.setState({
      page: page,
    })

    this.getEvents(page, this.state.selectedEventType, this.state.selectedMachineId, this.state.selectedCustomerId, this.state.showHidden)
  }

  setSelectedCustomerId(option) {
    this.setState({
      selectedCustomerId: option.value,
    })

    this.getEvents(this.state.page, this.state.selectedEventType, this.state.selectedMachineId, option.value, this.state.showHidden)
  }

  getEvents(page, eventType, machineId, customerId, showHidden) {
    this.setState({
      loadingEvents: true
    })
    let that = this;

    const formData = new FormData();
    let data = JSON.stringify({
      page: page,
      selectedEventType: eventType,
      selectedMachineId: machineId,
      selectedCustomerId: customerId,
      showHidden: showHidden,
    })
    formData.append('event', data)

    fetch(this.props.url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          customers: data.customers,
          loadingEvents: false,
          sortedEvents: data.events,
          eventNames: data.event_names,
          page: parseInt(data.page),
        })
      }
    })
  }

  toggleHidden() {
    let hidden = this.state.showHidden;

    this.setState({
      showHidden: !hidden,
    })

    this.getEvents(this.state.page, this.state.selectedEventType, this.state.selectedMachineId, this.state.selectedCustomerId, !hidden)
  }

  render () {
    let { machines, showdrop_logo } = this.props;
    let {
      customers,
      downloading,
      eventNames,
      fullyLoaded,
      loadingEvents,
      onDesktop,
      selectedEventType,
      selectedMachineId,
      selectedCustomerId,
      showHidden,
      page,
    } = this.state;

    const filteredEvents = this.state.sortedEvents

    const eventsAmount = () => {
      if (loadingEvents) {
        return (
          <div className="col">
            <span className="bold">LOADING...</span>
          </div>
        )
      } else {
        if (fullyLoaded) {
          return (
            <div className="col">
              <span className="bold">{filteredEvents.length}</span> events loaded
            </div>
          )
        } else {
          return (
            <div className="col">
              <span className="bold">{filteredEvents.length}</span> events loaded
            </div>
          )
        }
      }
    }

    const rowHeight = ({index}) => {
      let event = filteredEvents[index];
      let lines = Math.max(event.brand_names.length || 0, event.campaign_ids.length || 0, 1);
      return lines * 30;
    }

    const wrapInDivs = (strings) => {
      return (<>{strings.map(s => (<div key={s}>{s}</div>))}</>)
    }

    const rowClassName = ({ index }) => {
      if (index == -1) return "bg-extra-gray" // header
      else {
        const event = filteredEvents[index];
        if (event.isContextEvent) return "color-light-purple"
      }
    }

    const showEvents = () => {
      if (filteredEvents.length == 0) {
        return null
      } else {
        return (
            <AutoSizer disableWidth>
              {({height}) => (
                <Table
                  width={3345}
                  height={height}
                  headerHeight={40}
                  headerStyle={{minHeight: "40px", textAlign: "center", fontSize: "smaller"}}
                  rowHeight={rowHeight}
                  rowCount={filteredEvents.length}
                  rowGetter={({index}) => filteredEvents[index]}
                  rowRenderer={eventTableRowRenderer}
                  rowStyle={{alignItems: "start"}}
                  rowClassName={rowClassName}>
                  <Column label="Event Id" headerRenderer={renderHeader} dataKey="id" width={80} />
                  <Column label="Created At" headerRenderer={renderHeader} dataKey="created_at" width={250} />
                  <Column label="Event Type" headerRenderer={renderHeader} dataKey="event_type" width={300} />
                  <Column label="Visit Id" headerRenderer={renderHeader} dataKey="visit_id" width={80} />
                  <Column label="Customer Id" headerRenderer={renderHeader} dataKey="customer.id" cellDataGetter={({rowData}) => rowData.customer?.id} width={80} />
                  <Column label="Customer Name" headerRenderer={renderHeader} dataKey="customer.name" cellDataGetter={({rowData}) => rowData.customer?.name } width={150} />
                  <Column label="Customer Email" headerRenderer={renderHeader} dataKey="customer.email" cellDataGetter={({rowData}) => rowData.customer?.email } width={230} />
                  <Column label="Customer Phone" headerRenderer={renderHeader} dataKey="display_phone" cellDataGetter={({rowData}) => rowData.display_phone } width={110} />
                  <Column label="Machine Name" headerRenderer={renderHeader} dataKey="machine.name" cellDataGetter={({rowData}) => rowData.machine?.name } width={205} />
                  <Column label="Campaign Ids" headerRenderer={renderHeader} dataKey="campaign_ids" cellRenderer={({cellData}) => wrapInDivs(cellData)} width={80} />
                  <Column label="Brand Names" headerRenderer={renderHeader} dataKey="brand_names" cellRenderer={({cellData}) => wrapInDivs(cellData)} width={120} />
                  <Column label="Product Names" headerRenderer={renderHeader} dataKey="product.name" cellDataGetter={({rowData}) => rowData.product?.name } width={300} />
                  <Column label="PIN" headerRenderer={renderHeader} dataKey="pin" width={80} />
                  <Column label="PIN Status" headerRenderer={renderHeader} dataKey="pin_status" width={80} />
                  <Column label="Stock Status" headerRenderer={renderHeader} dataKey="stock_status" width={80} />
                  <Column label="Eligible Status" headerRenderer={renderHeader} dataKey="eligible_status" width={80} />
                  <Column label="Overstock Select" headerRenderer={renderHeader} dataKey="overstock_select" width={80} />
                  <Column label="Engagement Type" headerRenderer={renderHeader} dataKey="engagement_type" width={100} />
                  <Column label="Survey Id" headerRenderer={renderHeader} dataKey="survey_question.id" cellDataGetter={({rowData}) => rowData.survey_question?.id} width={80} />
                  <Column label="Survey Message" headerRenderer={renderHeader} dataKey="survey_question.message" cellDataGetter={({rowData}) => rowData.survey_question?.message} width={100} />
                  <Column label="Rebate Quantity" headerRenderer={renderHeader} dataKey="rebate_quantity" cellDataGetter={({rowData}) => rowData.rebate_quantity } width={80} />
                  <Column label="Rebate Amount" headerRenderer={renderHeader} dataKey="rebate_amount" cellDataGetter={({rowData}) => rowData.rebate_amount } width={80} />
                  <Column label="Admin Hidden" headerRenderer={renderHeader} dataKey="admin_hidden" width={80} />
                </Table>
              )}
            </AutoSizer>
        )
      }
    }

    const allCustomersOption = { value: "all", label: "All Customers" }
    const customerOptions = customers.map(customer => {
      const label = [`#${customer.id}`, customer.name, customer.email, customer.phone].filter(Boolean).join(' ')
      return { value: customer.id, label }
    })
    const customersSelectOptions = [allCustomersOption, ...customerOptions]
    const selectedOption = customersSelectOptions.find(o => o.value == selectedCustomerId)

    const customerSelect = (
      <li className="nav-item">
        <div className="form-group">
          <label htmlFor="customer-select">Customers</label>
          {customersSelectOptions.length > 1 && (
            <Select
              inputId="customer-select"
              value={selectedOption}
              onChange={this.setSelectedCustomerId}
              options={customersSelectOptions}
            />)}
        </div>
      </li>
    )

    let pageName = 'machine_events_v2';

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col col-lg-10 bg-white o-auto d-flex flex-column" style={{ height:"1600px" }}>
            <IndexHeader
              name={'Raw Events V2'}
              url={null}
            />

            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <div className="form-group">
                  <label className="float-left">Event Types</label>
                  <select className="form-select" value={selectedEventType} onChange={this.filterEventsByType} name="event" disabled={loadingEvents ? 'disabled' : ''}>
                    <option key={`event-none`} value={null}></option>

                    {eventNames.map(event => {
                      return (
                        <option key={`event-${event}`} value={event}>{event}</option>
                      )
                    })}
                  </select>
                </div>

                <div className="form-group left-text pl-20">
                  <input type="checkbox" className="form-check-input" onChange={this.toggleHidden} checked={showHidden} disabled={loadingEvents ? 'disabled' : ''} />
                  <label>Show Hidden Events</label>
                </div>
              </li>

              <li className="nav-item">
                <div className="form-group">
                  <label className="float-left">Machines</label>
                  <select className="form-select" value={selectedMachineId} onChange={this.filterEventsByMachine} name="machine" disabled={loadingEvents ? 'disabled' : ''}>
                    <option key={`machine-none`} value={null}></option>

                    {machines.map(machine => {
                      return (
                        <option key={`machine-${machine.id}`} value={machine.id}>{machine.nickname} [{machine.id}]</option>
                      )
                    })}
                  </select>
                </div>
              </li>

              {customerSelect}

              <li className="nav-item w40fs">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download ALL Events")}
                >
                  <button type="button" className="btn btn-sm btn-primary mb-5px mt-30 fs-17 w35" onClick={this.downloadCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-file-download"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </li>

              <li className="nav-item w40fs">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Transaction Details")}
                >
                  <button type="button" className="btn btn-sm btn-warning mb-5px mt-30 fs-17 w35" onClick={this.downloadTransactionDetailsCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-desktop"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </li>

              <li className="nav-item w40fs">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 100 }}
                  overlay={renderTooltip("Download Machine Logs")}
                >
                  <button type="button" className="btn btn-sm btn-info mb-5px mt-30 fs-17 w35" onClick={this.downloadMachineLogCsv} disabled={downloading ? "disabled" : ''}>
                    {
                      downloading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <i className="fas fa-history"></i>
                      )
                    }
                  </button>
                </OverlayTrigger>
              </li>
            </ul>

            <div className="row pb-10">
              {eventsAmount()}
            </div>

            { loadingEvents ? (
                <div className="m-auto w100p o-x-scroll h1000 center-text">
                  <i className="fas fa-circle-notch fa-spin fs-80"></i>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col" style={{ textAlign:"center",margin:"10px",fontSize:"18px",color:"white",fontWeight:"bold" }}>
                      {
                        page > 1 ? (
                          <div className="clickable" onClick={() => this.setPage(page - 1)} style={{ padding:"10px",backgroundColor:"orange",borderRadius:"4px" }}>
                            Previous
                          </div>
                        ) : (
                          null
                        )
                      }
                    </div>

                    <div className="col" style={{ textAlign:"center",margin:"10px",fontSize:"18px",color:"white",fontWeight:"bold" }}>
                      {
                        filteredEvents.length == 5000 ? (
                          <div className="clickable" onClick={() => this.setPage(page + 1)} style={{ padding:"10px",backgroundColor:"orange",borderRadius:"4px" }}>
                            Next
                          </div>
                        ) : (
                          null
                        )
                      }
                    </div>
                  </div>

                  <div className="row flex-grow-1">
                    {showEvents()}
                  </div>
                </>
              )
            }

          </div>
        </div>
      </div>
    )
  }
}

function renderHeader({label}) {
  return (
    <span
      style={{
        display: "inline-block",
        maxWidth: "100%",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
      key="label"
      title={typeof label === 'string' ? label : null}>
      {typeof label === 'string' ? label.match(/[^\s,]+/g).flatMap(w => [w, <br/>]) : label}
    </span>
  );
}

function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};

function eventTableRowRenderer(props) {
  const key = props.rowData.id
  return defaultTableRowRenderer({...props, key})
}
