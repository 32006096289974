import React from "react";
import CampaignRow from "./CampaignRow";
import ProductRow from "./ProductRow";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Bar } from "react-chartjs-2";
import BrandPortalNav from "./BrandPortalNav";

export default class AllWaves extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'all_campaigns'
    };
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let { activePage } = this.state;

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-extra-gray">
              <div className="col">
                <h5 style={{ marginTop:"20px",color:"#616161" }}>
                  All Campaigns
                </h5>
              </div>
            </div>

            <div className="row bg-extra-gray">
              <div className="col">
                <h2 className="bold" style={{ marginTop:"20px" }}>Order By</h2>
              </div>
            </div>

            {
              brand.waves.map(wave => {
                return (
                  <a href={wave.url} style={{ color:"black",textDecoration:"none" }}>
                    <div className="row bg-white br-4" style={{ margin:"20px 5px" }}>
                      <div className="col" style={{ padding:"20px 40px" }}>
                        <div style={{ display:"flex" }}>
                          <div style={{ flex:"1" }}>
                            <div>
                              <span style={{ fontSize:"24px",fontWeight:"bold" }}>{wave.name}</span>
                            </div>
                            <div>{wave.launch_date}</div>
                          </div>

                          <div style={{ flex:"0.5" }}>
                            <span style={{ fontSize:"16px",color:"#616161" }}>
                              {
                                wave.active ? (
                                  <>
                                    <i className="fas fa-circle" style={{ color:"#20cb9a",padding:"5px",fontSize:"12px" }}></i>

                                    Active
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-circle" style={{ color:"#616161",padding:"5px",fontSize:"12px" }}></i>

                                    Completed
                                  </>
                                )
                              }
                            </span>
                          </div>

                          <div style={{ flex:"0.5" }}>
                            {wave.total_stores} Stores
                          </div>

                          <div style={{ flex:"1" }}>
                            <div style={{ display:"flex" }}>
                              <div style={{ flex:"1",minWidth:"140px" }}>
                                {
                                  wave.products.map(product => {
                                    return (
                                      <img style={{ width:"60px",flex:"1",margin:"5px" }} src={product.image} />
                                    )
                                  })
                                }
                              </div>
                              <div style={{ flex:"1" }}>
                                <ul className="no-list" style={{ padding:"0",margin:"0" }}>
                                  {
                                    wave.products.map(product => {
                                      return (
                                        <li style={{ padding:"2px 5px" }}>{product.name}</li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div style={{ flex:"1" }}>
                            <div style={{ display:"flex" }}>
                              <div style={{ flex:"1",textAlign:"center",borderRight:"1px solid black" }}>
                                <div style={{ fontSize:"24px" }}>{numberWithCommas(wave.samples)}</div>
                                <div>Samples</div>
                              </div>
                              <div style={{ flex:"1",textAlign:"center",borderRight:"1px solid black" }}>
                                <div style={{ fontSize:"24px" }}>{numberWithCommas(wave.ratings)}</div>
                                <div>Ratings</div>
                              </div>
                              <div style={{ flex:"1",textAlign:"center" }}>
                                <div style={{ fontSize:"24px" }}>{numberWithCommas(wave.reviews)}</div>
                                <div>Reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

function SumArray(data) {
  return data.reduce((a, v) => (a = a + v), 0);
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
