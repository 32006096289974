import React from "react";
import CampaignRow from "./CampaignRow";
import ProductRow from "./ProductRow";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Bar } from "react-chartjs-2";
import BrandPortalNav from "./BrandPortalNav";

export default class Wave extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: this.props.brand.latest_wave.active ? 'active_campaign' : 'all_campaigns',
      ageDataExists: false,
      ageDataLoading: true,
      ageData: {},
      genderDataExists: false,
      genderDataLoading: true,
      genderData: {},
      downloading: false,
      filteredCampaigns: this.props.campaigns,
      filteredProducts: this.props.products,
      selectedWave: "All",
      selectedWaveId: 0,
      totalClickThroughs: 0,
      totalRebateRedemptions: 0,
      totalRebateSignUps: 0,
      totalImpressions: 0,
      totalDwells: 0,
      totalQrScans: 0,
      totalSamplesClaimed: 0,
      clicks: 0,
    };

    this.collectClickThroughs = this.collectClickThroughs.bind(this)
    this.collectRebateRedemptions = this.collectRebateRedemptions.bind(this)
    this.collectRebateSignUps = this.collectRebateSignUps.bind(this)
    this.collectImpressions = this.collectImpressions.bind(this)
    this.collectDwells = this.collectDwells.bind(this)
    this.collectQrScans = this.collectQrScans.bind(this)
    this.collectSamplesClaimed = this.collectSamplesClaimed.bind(this)
    this.downloadCustomersCsv = this.downloadCustomersCsv.bind(this)
    this.fetchAgeData = this.fetchAgeData.bind(this)
    this.fetchGenderData = this.fetchGenderData.bind(this)
    this.setSelectedWave = this.setSelectedWave.bind(this)
    this.setActivePage = this.setActivePage.bind(this)
  }

  componentDidMount() {
    this.fetchAgeData(0)
    this.fetchGenderData(0)
  }

  collectClickThroughs(e) {
    let currentCount = this.state.totalClickThroughs

    this.setState({
      totalClickThroughs: currentCount + e
    })
  }

  collectRebateRedemptions(e) {
    let currentCount = this.state.totalRebateRedemptions

    this.setState({
      totalRebateRedemptions: currentCount + e
    })
  }

  collectRebateSignUps(e) {
    let currentCount = this.state.totalRebateSignUps

    this.setState({
      totalRebateSignUps: currentCount + e
    })
  }

  collectImpressions(e) {
    let currentCount = this.state.totalImpressions

    this.setState({
      totalImpressions: currentCount + e
    })
  }

  collectDwells(e) {
    let currentCount = this.state.totalDwells

    this.setState({
      totalDwells: currentCount + e
    })
  }

  collectQrScans(e) {
    let currentCount = this.state.totalQrScans

    this.setState({
      totalQrScans: currentCount + e
    })
  }

  collectSamplesClaimed(e) {
    let currentCount = this.state.totalSamplesClaimed

    this.setState({
      totalSamplesClaimed: currentCount + e
    })
  }

  downloadCustomersCsv() {
    if (prompt("Please enter your password") ==  this.props.brand.download_password) {
      let that = this;
      that.setState({
        downloading: true
      })

      fetch(this.props.url_download_raw_customers_csv, {
        method: 'GET',
        headers: {}
      }).then(response => {
        return response.blob();
      }).then((blob) => {
        that.setState({
          downloading: false
        })
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        link.setAttribute(
          'download',
          `showdrop_customers_${date}.csv`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
    }
  }

  fetchAgeData(id) {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      wave_id: id,
    })
    formData.append('brand', data)

    fetch(this.props.url_fetch_age_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          ageDataExists: data.age_data_exists,
          ageDataLoading: false,
          ageData: data.age_data,
        })
      }
    })
  }

  fetchGenderData(id) {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      wave_id: id,
    })
    formData.append('brand', data)

    fetch(this.props.url_fetch_gender_data, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        that.setState({
          genderDataExists: data.gender_data_exists,
          genderDataLoading: false,
          genderData: data.gender_data,
        })
      }
    })
  }

  setSelectedWave(str, id) {
    let totalClickThroughs = 0
    let totalRebateRedemptions = 0
    let totalRebateSignUps = 0
    let totalImpressions = 0
    let totalDwells = 0
    let totalQrScans = 0
    let totalSamplesClaimed = 0

    const campaignsByWave = this.props.campaigns.filter(campaign => {
      if (campaign.wave_id == id) {
        return campaign
      }
    })

    let clicks = this.state.clicks + 1

    this.setState({
      selectedWave: str,
      selectedWaveId: id,
      ageDataExists: false,
      ageDataLoading: true,
      genderDataExists: false,
      genderDataLoading: true,
      totalClickThroughs: totalClickThroughs,
      totalRebateRedemptions: totalRebateRedemptions,
      totalRebateSignUps: totalRebateSignUps,
      totalImpressions: totalImpressions,
      totalDwells: totalDwells,
      totalQrScans: totalQrScans,
      totalSamplesClaimed: totalSamplesClaimed,
      filteredCampaigns: id == 0 ? this.props.campaigns : campaignsByWave,
      clicks: clicks
    })

    this.fetchAgeData(id)
    this.fetchGenderData(id)
  }

  setActivePage(e) {
    this.setState({
      activePage: e
    })
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let {
      activePage,
      ageDataExists,
      ageDataLoading,
      ageData,
      clicks,
      genderDataExists,
      genderDataLoading,
      genderData,
      downloading,
      filteredCampaigns,
      filteredProducts,
      selectedWave,
      selectedWaveId,
      totalClickThroughs,
      totalRebateRedemptions,
      totalRebateSignUps,
      totalImpressions,
      totalDwells,
      totalQrScans,
      totalSamplesClaimed,
    } = this.state;

    let orderWord = () => {
      if (brand.order_count == 1) {
        return "Order"
      } else {
        return "Orders"
      }
    }
    let showRebateSignUpHeader = () => {
      if (totalRebateSignUps > 0) {
        return (
          <>
            <th className="bg-extra-gray">Rebate Sign Ups</th>
            {
              totalRebateRedemptions > 0 ? (
                <th className="bg-extra-gray">Rebate Redemptions</th>
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let showClickThroughHeader = () => {
      if (totalClickThroughs > 0) {
        return (
          <th className="bg-extra-gray">Customer Clicks</th>
        )
      } else {
        return null;
      }
    }
    let showRebateCountForAll = () => {
      if (totalRebateSignUps > 0) {
        return (
          <>
            <td>{numberWithCommas(totalRebateSignUps)}</td>
            {
              totalRebateRedemptions > 0 ? (
                <td>{numberWithCommas(totalRebateRedemptions)}</td>
              ) : (
                null
              )
            }
          </>
        )
      } else {
        return null;
      }
    }
    let showClicksForAll = () => {
      if (totalClickThroughs > 0) {
        return (
          <td>{numberWithCommas(totalClickThroughs)}</td>
        )
      } else {
        return null
      }
    }
    let showRebateLegend = () => {
      if (totalRebateSignUps > 0) {
        return (
          <tr>
            <td className="bold bg-extra-gray">Rebate Sign Ups</td>
            <td># of rebate sign ups</td>
          </tr>
        )
      } else {
        return null;
      }
    }
    const displayShopifyOrders = (brand) => {
      if (brand.order_count > 0) {
        return (
          <div className="row ptb20-lr10 bg-extra-gray">
            <div className="col col-lg-4">
              <div className="table-responsive w100p m-auto bg-white">
                <table className="table table-sm center-text table-black-border mb-0">
                  <thead>
                    <tr>
                      <th className="bg-extra-gray">Directly Attributable Shopify Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{numberWithCommas(brand.order_count)} {orderWord()}</td>
                    </tr>

                    <tr>
                      <td>{brand.order_amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      }
    }
    let demographicBarGraph = (graph_data) => {
      if (JSON.stringify(graph_data) === '{}') {
        return (
          <></>
        )
      } else {
        const data = {
          labels: graph_data.labels,
          datasets: graph_data.datasets,
        };
        const options = {
          barPercentage: 1,
          categoryPercentage: 1,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
              max: 100
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              position: "bottom",
            },
            tooltip: {
              mode: 'index',
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (context.parsed.x !== null) {
                    label += ' ' +context.parsed.x + '%';
                  }
                  return label;
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
        };

        return (
          <Bar data={data} options={options} height={"160px"} />
        );
      }
    };
    let buttonFilter = (str, id) => {
      if (ageDataExists && genderDataExists) {
        return (
          <div className="hoverable" onClick={() => this.setSelectedWave(str, id)} style={{ flex:"1",textAlign:"center",margin:"5px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",minWidth:"200px",maxWidth:"200px",backgroundColor:`${str == selectedWave ? "#20cb9a" : "white"}`,color:`${str == selectedWave ? "white" : "black"}` }}>
            {str}
          </div>
        )
      } else {
        if (str == selectedWave) {
          return (
            <div onClick={null} style={{ flex:"1",textAlign:"center",margin:"5px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",minWidth:"200px",maxWidth:"200px",backgroundColor:"#20cb9a",color:"white" }}>
              {
                str == selectedWave ? (
                  <>
                    <i className="fas fa-circle-notch fa-spin"></i>
                    <span style={{ paddingLeft:"4px" }}>{str}</span>
                  </>
                ) : (
                  <span>{str}</span>
                )
              }
            </div>
          )
        } else {
          return (
            <div onClick={null} style={{ flex:"1",textAlign:"center",margin:"5px",padding:"10px",borderRadius:"4px",fontSize:"16px",fontWeight:"bold",minWidth:"200px",maxWidth:"200px",backgroundColor:"#a8a8a8",color:"white" }}>
              {str}
            </div>
          )
        }
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-extra-gray">
              <div className="col">
                <h5 style={{ marginTop:"20px",color:"#616161" }}>
                  {
                    this.props.brand.latest_wave.active ? (
                      "Active Campaign"
                    ) : (
                      "Completed Campaign"
                    )
                  }
                </h5>
              </div>
            </div>

            <div className="row bg-extra-gray">
              <div className="col">
                <h2 className="bold" style={{ marginTop:"20px" }}>{brand.latest_wave.name} Campaign</h2>
              </div>
            </div>

            <div className="row bg-white br-4" style={{ margin:"20px 5px" }}>
              <div className="col" style={{ padding:"20px 40px 40px" }}>
                <div style={{ padding:"5px 0" }}>
                  <span style={{ fontSize:"24px",paddingRight:"20px",fontWeight:"bold" }}>
                    Campaign Details
                  </span>

                  <span style={{ fontSize:"16px",color:"#616161" }}>
                    {
                      brand.latest_wave.active ? (
                        <>
                          <i className="fas fa-circle" style={{ color:"#20cb9a",padding:"5px" }}></i>

                          Active
                        </>
                      ) : (
                        <>
                          <i className="fas fa-circle" style={{ color:"#616161",padding:"5px" }}></i>

                          Completed
                        </>
                      )
                    }
                  </span>
                </div>

                <div style={{ display:"flex" }}>
                  <div style={{ flex:"1" }}>
                    <div style={{ fontSize:"22px",fontWeight:"bold",padding:"5px 0" }}>Dates</div>

                    <div style={{ fontSize:"18px",display:"flex",padding:"5px 0" }}>
                      <div style={{ flex:"1",fontWeight:"bold" }}>Launch Date</div>
                      <div style={{ flex:"1",color:"#616161" }}>{brand.latest_wave.launch_date}</div>
                    </div>

                    <div style={{ fontSize:"18px",display:"flex",padding:"5px 0" }}>
                      <div style={{ flex:"1",fontWeight:"bold" }}>Days Live</div>
                      <div style={{ flex:"1",color:"#616161" }}>{brand.latest_wave.days_live} days</div>
                    </div>
                  </div>

                  <div style={{ flex:"1" }}>
                    <div style={{ fontSize:"22px",fontWeight:"bold",padding:"5px 0" }}>Locations</div>

                    <div style={{ display:"flex",fontSize:"18px" }}>
                      {/* <div style={{ flex:"1" }}>
                        <div style={{ display:"flex",padding:"5px 0" }}>
                          <div style={{ flex:"1",fontWeight:"bold" }}>Retailer</div>
                          <div style={{ flex:"1",color:"#616161" }}>Marianos</div>
                        </div>
                      </div> */}
                      <div style={{ flex:"1" }}>
                        <div style={{ display:"flex",padding:"5px 0" }}>
                          <div style={{ flex:"0.75",fontWeight:"bold" }}>Total Stores</div>
                          <div style={{ flex:"0.25",color:"#616161" }}>{brand.latest_wave.total_stores}</div>
                        </div>

                        <div style={{ display:"flex",padding:"5px 0" }}>
                          <div style={{ flex:"0.75",fontWeight:"bold" }}>Active Stores</div>
                          <div style={{ flex:"0.25",color:"#616161" }}>{brand.latest_wave.active_stores}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ flex:"1" }}>
                    <div style={{ fontSize:"22px",fontWeight:"bold",padding:"5px 0" }}>Other Details</div>

                    <div style={{ display:"flex",fontSize:"18px",padding:"5px 0" }}>
                      <div style={{ flex:"0.75",fontWeight:"bold" }}>Rebates Enabled</div>
                      <div style={{ flex:"0.25",color:"#616161" }}>{brand.latest_wave.rebates_enabled}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div style={{ display:"flex" }}>
                  <div className="bg-white br-4" style={{ flex:"1",padding:"20px 40px 30px",margin:"10px 10px 10px 5px" }}>
                    <div style={{ fontSize:"40px",fontWeight:"bold" }}>{numberWithCommas(brand.latest_wave.impressions)}</div>
                    <div style={{ fontSize:"20px",color:"#616161" }}>Impressions</div>
                  </div>

                  <div className="bg-white br-4" style={{ flex:"1",padding:"20px 40px 30px",margin:"10px" }}>
                    <div style={{ fontSize:"40px",fontWeight:"bold" }}>{numberWithCommas(brand.latest_wave.dwells)}</div>
                    <div style={{ fontSize:"20px",color:"#616161" }}>Dwells</div>
                  </div>

                  <div className="bg-white br-4" style={{ flex:"1",padding:"20px 40px 30px",margin:"10px" }}>
                    <div style={{ fontSize:"40px",fontWeight:"bold" }}>{numberWithCommas(brand.latest_wave.qr_scans)}</div>
                    <div style={{ fontSize:"20px",color:"#616161" }}>QR Scans</div>
                  </div>

                  <div className="bg-white br-4" style={{ flex:"1",padding:"20px 40px 30px",margin:"10px 5px 10px 10px" }}>
                    <div style={{ fontSize:"40px",fontWeight:"bold" }}>{numberWithCommas(brand.latest_wave.samples)}</div>
                    <div style={{ fontSize:"20px",color:"#616161" }}>Samples</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row bg-white br-4" style={{ margin:"20px 5px" }}>
              <div className="col" style={{ padding:"20px 40px 40px" }}>
                <div style={{ flex:"1",padding:"10px" }}>
                  <h2 style={{ padding:"0 0 10px",fontWeight:"bold" }}>Total Demographic Breakdown</h2>

                  <div style={{ height:"160px" }}>
                    {
                      genderDataExists ? (
                        demographicBarGraph(genderData)
                      ) : (
                        genderDataLoading ? (
                          <div style={{ height:"140px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                            <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>
                              <i className="fas fa-circle-notch fa-spin"></i>
                              <span style={{ paddingLeft:"4px" }}>Loading Gender Data...</span>
                            </div>
                          </div>
                        ) : (
                          <div style={{ height:"140px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                            <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Gender Data Unavailable</div>
                          </div>
                        )
                      )
                    }
                  </div>

                  <div style={{ height:"160px" }}>
                    {
                      ageDataExists ? (
                        demographicBarGraph(ageData)
                      ) : (
                        ageDataLoading ? (
                          <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                            <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>
                              <i className="fas fa-circle-notch fa-spin"></i>
                              <span style={{ paddingLeft:"4px" }}>Loading Age Data...</span>
                            </div>
                          </div>
                        ) : (
                          <div style={{ height:"160px",backgroundColor:"#00000020",color:"white",fontWeight:"bold",position:"relative",margin:"5px" }}>
                            <div style={{ position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)",fontSize:"20px" }}>Age Data Unavailable</div>
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row bg-white br-4" style={{ margin:"30px 5px" }}>
              <div className="col" style={{ padding:"20px 40px" }}>
                <div style={{ flex:"1",padding:"10px" }}>
                  <h2 style={{ padding:"0 0 10px",fontWeight:"bold" }}>Weekly Overview</h2>

                  <div className="row ptb20-lr10">
                    <div className="col col-lg-12">
                      <div className="table-responsive w100p m-auto bg-white">
                        <table className="table table-sm center-text table-black-border mb-0">
                          <thead>
                            <tr>
                              <th className="bg-extra-gray"></th>
                              <th className="bg-extra-gray">Launch Date</th>
                              <th className="bg-extra-gray">Location</th>
                              <th className="bg-extra-gray">Product</th>
                              <th></th>
                              <th className="bg-light-purple">Impressions</th>
                              <th className="bg-purple">Dwells</th>
                              <th className="bg-showdrop-yellow">QR Scans</th>
                              <th className="bg-showdrop">Samples Claimed</th>
                              {
                                totalClickThroughs > 0 || totalRebateSignUps > 0 ? (
                                  <th></th>
                                ) : (
                                  null
                                )
                              }
                              {showClickThroughHeader()}
                              {showRebateSignUpHeader()}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredCampaigns.map(campaign => {
                              return (
                                <CampaignRow
                                  clicks={clicks}
                                  campaign={campaign}
                                  collectClickThroughs={this.collectClickThroughs}
                                  totalClickThroughs={totalClickThroughs}
                                  collectRebateRedemptions={this.collectRebateRedemptions}
                                  totalRebateRedemptions={totalRebateRedemptions}
                                  collectRebateSignUps={this.collectRebateSignUps}
                                  totalRebateSignUps={totalRebateSignUps}

                                  collectImpressions={this.collectImpressions}
                                  totalImpressions={totalImpressions}
                                  collectDwells={this.collectDwells}
                                  totalDwells={totalDwells}
                                  collectQrScans={this.collectQrScans}
                                  totalQrScans={totalQrScans}
                                  collectSamplesClaimed={this.collectSamplesClaimed}
                                  totalSamplesClaimed={totalSamplesClaimed}
                                />
                              );
                            })}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{numberWithCommas(totalImpressions)}</td>
                              <td>{numberWithCommas(totalDwells)}</td>
                              <td>{numberWithCommas(totalQrScans)}</td>
                              <td>{numberWithCommas(totalSamplesClaimed)}</td>
                              {
                                totalClickThroughs > 0 || totalRebateSignUps > 0 ? (
                                  <td></td>
                                ) : (
                                  null
                                )
                              }
                              {showClicksForAll()}
                              {showRebateCountForAll()}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {displayShopifyOrders(brand)}

                  <div className="row ptb20-lr10">
                    <div className="col">
                      <div className="table-responsive w100p m-auto bg-white">
                        <table className="table table-sm table-black-border mb-0">
                          <thead className="center-text">
                            <tr>
                              <th className="bg-extra-gray" colSpan={2}>Legend</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td className="bold bg-light-purple">Impressions</td>
                              <td># of times people walk by the Showdrop kiosk</td>
                            </tr>

                            <tr>
                              <td className="bold bg-purple">Dwells</td>
                              <td># of times people spend time directly in front of kiosk screen</td>
                            </tr>

                            <tr>
                              <td className="bold bg-showdrop-yellow">QR Scans</td>
                              <td># of customers who began selection of samples</td>
                            </tr>

                            <tr>
                              <td className="bold bg-showdrop">Samples Claimed</td>
                              <td># of customers who selected and received sample</td>
                            </tr>

                            <tr>
                              <td className="bold bg-extra-gray">Customer Clicks</td>
                              <td># of times customers clicked on the links sent to them</td>
                            </tr>

                            {showRebateLegend()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row bg-white br-4" style={{ margin:"30px 5px" }}>
              <div className="col" style={{ padding:"20px 40px" }}>
                <div style={{ flex:"1",padding:"10px" }}>
                  <h2 style={{ padding:"0 0 10px",fontWeight:"bold" }}>Product Reviews</h2>

                  <div className="accordion">
                    {filteredProducts.map((product,i) => {
                      return (
                        <ProductRow
                          selectedWaveId={selectedWaveId}
                          product={product}
                          i={i}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SumArray(data) {
  return data.reduce((a, v) => (a = a + v), 0);
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
