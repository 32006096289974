import React from "react"
import ProductRow from "./ProductRow";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    let { product_reviews } = this.props;

    return (
      <div className="bg-showdrop">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-10 col-md-6 col-lg-4 pt-30">
              <h2 className="color-secondary fs-15rem bold">
                My Ratings
              </h2>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-10 col-md-6 col-lg-4">
              <ul style={{ listStyleType:"none",padding:"0",margin:"0" }}>
                {product_reviews.map(pr => {
                  return (
                    <ProductRow
                      pr={pr}
                      customerId={this.props.id}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
