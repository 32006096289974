import React from "react"
import ReactStars from "react-rating-stars-component";
import { Modal } from "react-bootstrap";

export default class ProductRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMessage: this.props.pr.message,
      currentRating: this.props.pr.rating,
      showModal: false,
    }

    this.changeMessage = this.changeMessage.bind(this)
    this.changeRating = this.changeRating.bind(this)
    this.executeSave = this.executeSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.saveReview = this.saveReview.bind(this)
  }

  changeMessage(e) {
    this.setState({
      currentMessage: e.target.value
    })
  }

  changeRating(e) {
    this.setState({
      currentRating: e
    })
  }

  handleClose() {
    this.setState({
      showModal: false
    })
  }

  handleOpen() {
    this.setState({
      showModal: true
    })
  }

  saveReview() {
    let blankMessage = !this.state.currentMessage

    if (blankMessage) {
      if(confirm("Are you sure you don't want to leave a comment?")) {
        this.executeSave();
      }
    } else {
      this.executeSave();
    }
  }

  executeSave() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      customer_id: this.props.customerId,
      product_id: this.props.pr.id,
      message: this.state.currentMessage,
      rating: this.state.currentRating,
    })
    formData.append('product_review', data)

    fetch(this.props.pr.update_url, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.reload()
      }
    })
  }

  render () {
    let { pr } = this.props;
    let { currentMessage, currentRating, showModal } = this.state;

    let determineCheck = (pr) => {
      if (pr.completed) {
        return (
          <>
            <div style={{ fontSize:"12px",textAlign:"center",fontWeight:"bold",color:"#20cb9a",width:"100%",lineHeight:"40px" }}>Complete!</div>
            <div style={{ maxWidth:"30px" }}>
              <i className="fas fa-check-circle" style={{ fontSize:"30px",padding:"5px 0",color:"#20cb9a" }}></i>
            </div>
          </>
        )
      } else if (pr.rating) {
        return (
          <>
            <div style={{ fontSize:"12px",textAlign:"center",width:"100%" }}>Leave a Comment!</div>
            <div style={{ maxWidth:"30px" }}>
              <i className="fas fa-arrow-alt-circle-right" style={{ fontSize:"30px",padding:"5px 0",color:"#3e3d37" }}></i>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div style={{ fontSize:"12px",textAlign:"center",width:"100%" }}>Submit Your Rating</div>
            <div style={{ maxWidth:"30px" }}>
              <i className="fas fa-arrow-alt-circle-right" style={{ fontSize:"30px",padding:"5px 0",color:"#3e3d37" }}></i>
            </div>
          </>
        )
      }
    }
    let determineProgress = (pr) => {
      if (pr.completed) {
        return null
      } else if (pr.rating) {
        return (
          <div style={{ display:"flex",margin:"5px 0 10px" }}>
            <div style={{ height:"5px",backgroundColor:"#FFA50099",flex:"1" }}></div>
            <div style={{ height:"5px",backgroundColor:"#00000020",flex:"1" }}></div>
            <div style={{ height:"5px",width:"30px",textAlign:"right",position:"relative",top:"-7px",color:"#FFA500",fontSize:"12px" }}>50%</div>
          </div>
        )
      } else {
        return (
          <div style={{ display:"flex",margin:"5px 0 10px" }}>
            <div style={{ height:"5px",backgroundColor:"#FF000099",flex:"0.05" }}></div>
            <div style={{ height:"5px",backgroundColor:"#00000020",flex:"0.95" }}></div>
            <div style={{ height:"5px",width:"30px",textAlign:"right",position:"relative",top:"-7px",color:"#FF0000",fontSize:"12px" }}>0%</div>
          </div>
        )
      }
    }

    return (
      <li style={{ margin:"10px 0",position:"relative" }}>
        <div className={`${pr.completed ? '' : 'clickable'}`} style={{ backgroundColor:"white",padding:"5px 10px 10px",borderRadius:"4px" }} onClick={pr.completed ? null : this.handleOpen}>
          {determineProgress(pr)}

          <div style={{ display:"flex" }}>
            <div style={{ flex:"0.65" }}>
              <div style={{ fontWeight:"bold",fontSize:"16px" }}>{pr.brand_name}</div>
              <div style={{ fontSize:"14px" }}>{pr.name}</div>
            </div>
            <div style={{ flex:"0.35",display:"flex" }}>
              {determineCheck(pr)}
            </div>
          </div>

          <div style={{ display:"flex" }}>
            <div style={{ maxWidth:"100px",marginTop:"10px" }}>
              <img src={pr.image} style={{ width:"100px" }} />
            </div>
            <div style={{ paddingLeft:"5px",width:"100%" }}>
              <div style={{ display:'flex',justifyContent:'center' }}>
                <ReactStars
                  count={5}
                  edit={false}
                  size={30}
                  isHalf={true}
                  a11y={true}
                  color="#00000020"
                  activeColor="#ffcb1399"
                  value={currentRating}
                  char="★"
                />
              </div>

              <div style={{ backgroundColor:"#00000005",padding:"5px",height:"65px",fontSize:"12px",maxHeight:"65px",overflow:"hidden",webkitLineClamp:"3",display:"-webkit-box",lineHeight:"1.6",webkitBoxOrient:"vertical",textOverflow:"ellipsis",borderRadius:"4px" }}>
                {
                  pr.message ? (
                    <>{pr.message}</>
                  ) : (
                    <span style={{ color:"#00000050" }}>{`Leave a comment for ${pr.brand_name}...`}</span>
                  )
                }
              </div>
            </div>
          </div>

          {
            pr.completed ? (
              <div style={{ backgroundColor:"#20cb9a50",position:"absolute",left:"0",top:"0",height:"100%",width:"100%" }}></div>
            ) : (
              null
            )
          }
        </div>

        {
          pr.rating_similarity ? (
            <div style={{ fontSize:"12px",color:'white',position:"relative",height:"25px",textAlign:"right" }}>
              <div style={{ backgroundColor:"#3e3d37",width:"230px",padding:"2px 6px",position:"absolute",right:"0",borderRadius:"0 0 4px 4px" }}>
                {`${pr.rating_similarity} of samplers rated the same as you`}
              </div>

              {
                pr.completed ? (
                  <div style={{ backgroundColor:"#20cb9a50",position:"absolute",left:"0",top:"0",height:"100%",width:"100%" }}></div>
                ) : (
                  null
                )
              }
            </div>
          ) : (
            null
          )
        }

        <ProductReviewModal
          changeMessage={this.changeMessage}
          changeRating={this.changeRating}
          currentMessage={currentMessage}
          currentRating={currentRating}
          handleClose={this.handleClose}
          pr={pr}
          saveReview={this.saveReview}
          showModal={showModal}
        />
      </li>
    );
  }
}

function ProductReviewModal(props) {
  let { changeMessage, changeRating, currentMessage, currentRating, handleClose, pr, saveReview, showModal } = props;

  if (showModal) {
    return (
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <div>
            <div style={{ fontWeight:"bold",fontSize:"20px" }}>{pr.brand_name}</div>
            <div style={{ fontSize:"14px" }}>{pr.name}</div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div style={{ textAlign:"center" }}>
            <img src={pr.image} style={{ width:"50%" }}></img>
          </div>

          <div style={{ display:'flex',justifyContent:'center' }}>
            <ReactStars
              count={5}
              onChange={changeRating}
              size={60}
              isHalf={true}
              a11y={true}
              color="#00000020"
              activeColor="#ffcb13"
              value={currentRating}
              char="★"
            />
          </div>

          <textarea className="form-control" style={{ backgroundColor:"#00000005",padding:"10px",borderRadius:"4px",minHeight:"150px",margin:"10px 0 20px" }} placeholder={`Leave a comment for ${pr.brand_name}...\n\nLet us know what made the product awesome or how it can be improved.`} onChange={changeMessage} value={currentMessage} />

          <button style={{ width:"100%",padding:"10px",borderRadius:"4px",border:"none",backgroundColor:"#20cb9a",fontSize:"18px",fontWeight:"bold",color:"white" }} onClick={saveReview}>
            Send to Brand
          </button>
        </Modal.Body>
      </Modal>
    )
  } else {
    return null;
  }
}
